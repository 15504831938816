<template>
  <div class="">
    <div class="gg-container">
      <div class="search-container" style="justify-content: space-between;">
        <div class="search-container-fn-input">
          <el-input
            style="width: 200px"
            size="small"
            placeholder="关键字搜索"
            prefix-icon="el-icon-search"
            clearable
            v-model="searchParams.org_name"
            @change="getList"
          >
          </el-input>
        </div>
        <div style="display: flex;">
          <div
          class="search-container-fn-input"
          style="margin-left: auto"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="success"
            icon="el-icon-plus"
            style="padding: 7px 8px;margin-left: 5px;"
            @click="editReply('add')"
          >
            <span style="font-size: 12px">新增</span>
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="delItem(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="editReply('edit', multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >编辑
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        </div>
      </div>
      <el-table
        border
        size="mini"
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        height="725"
        @selection-change="handleSelectionChange"
        style="width: 100%;z-index:0"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" align="center" width="50">
        </el-table-column>
        <template v-for="(column, index) in tableHead">
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-if="column.field_type === 'textBtn' && column.visible === true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a
                style="font-size: 12px; color: #2379fb"
                @click.prevent="editArticle('view', scope.row)"
              >
                {{ scope.row[column.column_prop] }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="
              column.field_type === 'select' && column.visible === true
            "
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="column.column_prop == 'is_recom'">
                <el-switch
                  class="switch"
                  @change="changeTopStatus($event, scope.row)"
                  v-model="scope.row.is_recom"
                  active-text="是"
                  inactive-text="否"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#C8C8C8"
                >
                </el-switch>
              </div>
              <div v-else-if="column.column_prop == 'status'">
                <el-switch
                  class="switch"
                  v-model="scope.row.status"
                  active-color="#13ce66"
                  inactive-color="#f5f5f5"
                  :active-value="1"
                  :inactive-value="0"
                  @change="swtichStatus(scope.row)"
                  active-text="启用"
                  inactive-text="禁用"
                >
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :sortable="tableHeadSortable.includes(column.column_prop)"
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="column.visible === true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a v-if="column.column_prop == 'third_status'">
                {{ scope.row.third_status ? "是" : "否" }}
              </a>
              <a v-else-if="column.column_prop == 'admin'">
                {{ scope.row.admin.name }}
              </a>
              <a v-else>{{ scope.row[column.column_prop] }}</a>
            </template>
            <template #header>
              <el-popover
                placement="bottom"
                title=""
                min-width="160"
                trigger="click"
                v-if="
                  searchTableHead.filter(
                    (item) => item.name == column.column_prop
                  ).length > 0
                "
              >
                <span slot="reference" class="search-header">
                  <span class="search-title">{{
                    column.column_label_user_definition
                      ? column.column_label_user_definition
                      : column.column_label
                  }}</span>
                  <i
                    style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                    class="el-icon-search"
                    :class="{
                      isSearch: searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                      'el-icon-zoom-in': searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                    }"
                  />
                </span>
                <el-select
                  v-if="'group_name' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.group_name)
                  "
                  v-model="searchParams.group_id"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="'trigger' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.trigger)
                  "
                  v-model="searchParams.trigger"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="'from_type' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.from_type)
                  "
                  v-model="searchParams.from_type"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in from_type_List"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="'client' == column.column_prop"
                  style="width: 200px"
                  @change="
                    handleSearch(column.column_prop, searchParams.client)
                  "
                  v-model="searchParams.client_type"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in clientList"
                    :key="index"
                    :label="item"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </el-popover>
              <span v-else>{{
                column.column_label_user_definition
                  ? column.column_label_user_definition
                  : column.column_label
              }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
      <!--新增编辑 快捷回复-->
    </div>

    <el-dialog
      :title="dialogTitle + '医院'"
      :visible.sync="dialogReplyVisible"
      width="600px"
      v-if="dialogReplyVisible"
    >
      <el-form
        ref="ruleForm"
        :model="currInfo"
        label-width="120px"
        :rules="rules"
        class="demo-ruleForm"
      >
        <el-form-item label="医院名称：" prop="org_name">
          <el-input
            type="text"
            v-model="currInfo.org_name"
            placeholder="请输入医院名称"
            show-word-limit
            maxlength="50"
            autofocus="true"
          ></el-input>
        </el-form-item>

        <el-form-item label="医院编码：" prop="org_code">
          <el-input
            type="text"
            v-model="currInfo.org_code"
            placeholder="请输入医院编码"
            show-word-limit
            maxlength="50"
          ></el-input>
        </el-form-item>

        <el-form-item label="省市区：" prop="area">
          <el-cascader
            style="width: 400px;"
            :options="pcaOptions"
            v-model="currInfo.area"
            @change="handleChange"
          >
          </el-cascader>
        </el-form-item>

        <el-form-item label="是否三甲：" prop="third_status">
          <el-select
            v-model="currInfo.third_status"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.title"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序：" prop="sort">
          <el-input style="width: 200px;" v-model="currInfo.sort"></el-input>
        </el-form-item>

        <el-form-item label="是否置顶：" prop="is_recom">
          <el-radio v-model="currInfo.is_recom" :label="1">是</el-radio>
          <el-radio v-model="currInfo.is_recom" :label="0">否</el-radio>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio v-model="currInfo.status" :label="1">启用</el-radio>
          <el-radio v-model="currInfo.status" :label="0">禁用</el-radio>
        </el-form-item>

        <el-form-item label="" prop="is_recom">
          <el-button type="success" size="small" @click="saveForm('ruleForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import { orgList, callMethod, hospitalTopStatus } from "@/api/basic/index";
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";
import { delHostpital, saveStatusOrg } from "@/api/basic";
import { regionData } from "element-china-area-data"; // secondary package based on el-pagination

export default {
  name: "hospitalManager",
  components: {
    Pagination,
    editTableHead,
  },
  computed: {
    dialogTitle() {
      return (
        {
          add: "添加",
          edit: "编辑",
        }[this.openType] || "添加"
      );
    },
    ...mapState({
      table_options: (state) => state.user.table_options,
    }),
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
  },
  data() {
    return {
      table_type:'hospitalManager',
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      // multipleSelection1: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "group_name",
          isSearch: false,
        },
        {
          name: "from_type",
          isSearch: false,
        },
        {
          name: "trigger",
          isSearch: false,
        },
        {
          name: "client",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      datetimerange: "",
      searchParams: {},
      dialogReplyVisible: false,
      currInfo: {},
      openType: "",
      dataLoading: false,
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      statusOptions: [
        {
          value: 1,
          label: "可见",
          title: "是",
        },
        {
          value: 0,
          label: "隐藏",
          title: "否",
        },
      ],
      pcaOptions: regionData,
      ruleForm: {},
      rules: {
        org_name: [
          { required: true, message: "请输入医院名称", trigger: "blur" },
        ],
        org_code: [
          { required: true, message: "请输入医院编码", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        area: [
          {
            required: true,
            message: "请选择医院所在省市区",
            trigger: "change",
          },
        ],
        third_status: [
          {
            required: true,
            message: "请选择医院是否为三甲",
            trigger: "change",
          },
        ],
        is_recom: [
          { required: true, message: "请选择置顶", trigger: "change" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      tableData: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      let tmp = val.map((v, k, a) => {
        return v.appraise_id;
      });
      this.multipleSelection = `[${tmp.join(",")}]`;
      console.log(this.multipleSelection, 43435435);
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "hospitalManager",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead =this.table_options.hospitalManager;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    changeTopStatus(e, row) {
      hospitalTopStatus(row.id, e)
        .then((res) => {
          if (res.code == 200) {
            var msg = e == 1 ? "已置顶" : "取消置顶";
            return this.$message.success(msg);
          } else {
            return this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(e);
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      orgList(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.data;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    editReply(type, item) {
      this.openType = type;
      if (type === "add") {
        this.currInfo = {};
      } else {
        this.currInfo = { ...item };
        this.currInfo.area = item.area + "";
        if (!item) {
          this.$message({
            type: "error",
            message: "请选择要编辑的快捷回复",
          });
          return;
        }
      }
      this.dialogReplyVisible = true;
    },
    delItem(item) {
      let params = {};
      params.org_id = item.id;
      this.$confirm(`是否删除医院 "${item.org_name}"?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          delHostpital(params)
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: data.msg + "," + data.code,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    swtichStatus(row) {
      let params = {};
      params.type = row.status;
      params.org_id = row.id;
      let title = "";
      if (row.status == 1) {
        title = "启用";
      } else {
        title = "禁用";
      }

      this.dataLoading = true;
      saveStatusOrg(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.$message({
              type: "success",
              message: title + "成功！",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          this.dataLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.dataLoading = false;
        });
    },

    handleChange(value) {
      this.currInfo.area = value[2];
      this.currInfo.city = value[1];
      this.currInfo.province = value[0];
    },
    setTop(row) {
      let params = { ...row };
      params.is_recom = 1;
      params.org_id = row.id;
      params.province = row.province + "";
      params.city = row.city + "";
      params.area = row.area + "";

      callMethod(params, "/admin/org/saveOrg", "post")
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.$message({
              type: "success",
              message: "置顶成功！",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveForm(form) {
      this.$refs[form].validate((valid, object) => {
        if (!valid) {
          let item;
          for (let key in object) {
            item = object[key][0];
            break;
          }
          this.$message({
            type: "error",
            message: item.message,
          });
          return;
        } else {
          let params = {};
          let url = "";
          params = { ...this.currInfo };
          if (this.openType == "add") {
            url = "/admin/org/addOrg"; //添加
          } else {
            //编辑
            url = "/admin/org/saveOrg";
            params.org_id = this.currInfo.id;
          }

          delete params.district_code;
          callMethod(params, url, "post")
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: this.dialogTitle + "医院成功！",
                });
                this.getList();
                this.dialogReplyVisible = false;
              } else {
                this.$message({
                  type: "error",
                  message: data.msg,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
    // 监听日期选择
    dateChange(val) {
      if (val && val.length) {
        this.searchParams.created_at = val[0];
        this.searchParams.start_at = val[0];
        this.searchParams.end_at = val[1];
      } else {
        this.searchParams.created_at = "";
        this.searchParams.start_at = "";
        this.searchParams.end_at = "";
      }
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  height: 26px;
  margin-bottom: 10px;

  i {
    width: 25px;
    height: 25px;
    background-color: #2632fb;
    color: white;
    line-height: 25px;
    text-align: center;
    margin-right: 8px;
    border-radius: 2px;
  }
}

.gg-container {
  position: relative;
}
.dot {
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  margin: 0 5px;
}

/*	.title-icon {
		margin-right: 15px;
		color: #fff;
		font-size: 18px;
		padding: 5px;
		border-radius: 2px;
		background: #2362fb;
	}*/

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
